<template>
  <AppListingBlockElement
    allow-intersect
    :is-shown="isShown"
    :data="productsForGallery"
    :data-listing-to-show="dataListingToShow"
    :show-loading-elements="isLoadingItems"
    v-on="$listeners">
    <template #bottomAction="{ item }">
      <div
        class="font-collection-name">
        <span class="font-collection-title">Collection:</span> {{ item.collectionName }}
      </div>
      <ProductDuplicateV2
        :is-store-product-preload="isStoreProductPreload"
        :duplicate-type="TYPE_FOLLOW"
        :collection-name="item.collectionName"
        :duplicated-collection-id="item.collection"
        :product-from-menu="item"
        :duplicated-product-ids="[item.id]">
        <template #duplicateButton="{ disableAction,btnText }">
          <div class="d-flex">
            <app-btn
              bottom
              :disabled="disableAction"
              :loading="showSpinner"
              :show-tooltip="disableAction"
              color="blue"
              class="mt-3"
              outlined>
              {{ btnText }}
            </app-btn>
          </div>
        </template>
      </ProductDuplicateV2>
    </template>
  </AppListingBlockElement>
</template>
<script>
import { TYPE_FOLLOW } from '@/constants';
export default {
  name: 'CollectionsSearchProductsList',
  components: {
    AppListingBlockElement: () => import('@/components/App/AppListingElements/AppListingBlockElement'),
    ProductDuplicateV2: () => import('@/components/Product/ProductDuplicateV2'),
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    showSpinner: {
      type: Boolean,
      default: false,
    },
    isStoreProductPreload: {
      type: Boolean,
      default: false,
    },
    isShown: {
      type: Boolean,
      default: false,
    },
    isLoadingItems: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dataListingToShow: ['Manufacturer', 'Model'],
      TYPE_FOLLOW,
    };
  },
  computed: {
    productsForGallery() {
      return this.items.map(({
        attachment: Image,
        model: Model,
        manufacturer: Manufacturer,
        ...rest
      }) => ({
        Image,
        Model,
        Manufacturer,
        'Product Type': rest.productType,
        ...rest,
      }));
    },
  },
};
</script>
<style scoped lang="scss">
  .font-collection-name {
    font-size: 16px;
  }
  .font-collection-title {
    font-size: 16px !important;
    line-height: 19px;
    color: #5E6061;
  }
</style>
